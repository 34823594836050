import { useMemo } from 'react';

import { InfiniteData, UseInfiniteQueryResult } from '@tanstack/react-query';

import MoreContentRequester from 'components/MoreContentRequester';
import { ActivityCardMenu } from 'components/patient/activities/ActivityMenu';
import NoteContent from 'components/patient/blurbs/activities/NoteContent';
import NoteContentHeader from 'components/patient/blurbs/activities/NoteContentHeader';
import NoteIcon from 'components/patient/blurbs/activities/NoteIcon';
import { EscalationFooter } from 'components/shared/EscalationFooter';
import { dateAtTime } from 'lib/date';
import ArchiveLocationEpisode from 'models/activities/ArchiveLocationEpisode';
import PatientMatchCollaboration from 'models/activities/PatientMatchCollaboration';
import RehabStateChange from 'models/activities/RehabStateChange';
import Activity, { ActivityType } from 'models/Activity';
import Episode from 'models/Episode';
import LocationEpisode from 'models/LocationEpisode';
import { AcuteLocationType } from 'models/LocationType';
import { Paginated } from 'models/Paginated';
import Profile from 'models/Profile';
import { colors } from 'styles/theme/colors';

import { Content, DateLabel, ModifiedBlurb, NoteHeader, SectionContainer, Separator } from './Layout';

function getBorderColor(activity: Activity) {
  const locationType = activity.createdBy?.locationType;

  if (activity.createdByGroupType?.isProvider) {
    return colors.accentGreen;
  }

  switch (locationType) {
    case 'physician_team':
    case AcuteLocationType.HOSPITAL:
    case AcuteLocationType.PHYSICIAN_GROUP:
    case AcuteLocationType.PAYER:
      return colors.pictonBlue;
    default:
      return colors.black10;
  }
}

function showBorder(activity: Activity) {
  return !(
    activity instanceof RehabStateChange ||
    activity instanceof PatientMatchCollaboration ||
    activity instanceof ArchiveLocationEpisode
  );
}

const showContent = (activity: Activity) =>
  activity.type !== ActivityType.PATIENT_MATCH_COLLABORATION &&
  activity.type !== ActivityType.REHAB_STATE_CHANGE &&
  activity.type !== ActivityType.ARCHIVE_LOCATION_EPISODE;

type AssembleNoteListInput = {
  activitiesQuery: UseInfiniteQueryResult<InfiniteData<Paginated<Activity>, unknown>, Error>;
  profile: Profile;
  episode: Episode;
  locationEpisode: LocationEpisode;
  initialData?: Activity[];
};

const ActivityList = (props: AssembleNoteListInput) => {
  const { activitiesQuery, episode, profile, locationEpisode } = props;

  const activities = useMemo(() => {
    const initialData = props.initialData || [];
    return activitiesQuery.data ? [...initialData, ...activitiesQuery.data.pages.flatMap((page) => page.data)] : [];
  }, [activitiesQuery.data, props.initialData]);

  return (
    <div>
      {activities.map((activity, activityIndex) => (
        <div key={activity.id}>
          {activityIndex > 0 && <Separator />}
          <ModifiedBlurb color={getBorderColor(activity)} $border={showBorder(activity)}>
            <NoteHeader>
              <div className='icon'>
                <NoteIcon activity={activity} />
              </div>
              <div>
                <NoteContentHeader activity={activity} />
                <DateLabel>Added {dateAtTime(activity.createdAt!)}</DateLabel>
              </div>
              <ActivityCardMenu activity={activity} profile={profile} />
            </NoteHeader>
            {showContent(activity) && (
              <Content>
                <NoteContent activity={activity} locationEpisode={locationEpisode} />

                {activity.escalation && (!episode.archived || activity.escalation.acknowledged) && (
                  <SectionContainer>
                    <EscalationFooter
                      locationEpisode={locationEpisode}
                      profile={profile}
                      escalation={activity.escalation}
                    />
                  </SectionContainer>
                )}
              </Content>
            )}
          </ModifiedBlurb>
        </div>
      ))}

      <MoreContentRequester resource={activitiesQuery} />
    </div>
  );
};

export default ActivityList;
