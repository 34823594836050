import styled from 'styled-components';

import Classification from 'models/Classification';
import LocationEpisode from 'models/LocationEpisode';
import { BodySmall, BodySmallBold, LabelBold } from 'styles/typography';
import ClipboardIcon from 'svg/ClipboardIcon';
import PayerIcon from 'svg/PayerIcon';

type Props = {
  locationEpisode: LocationEpisode;
};

export default function PatientClassifications({ locationEpisode }: Props) {
  return (
    <TableSection>
      <TableHeader>
        <LabelBold>PLAN TYPE + EPISODE INFORMATION</LabelBold>
      </TableHeader>
      <Row>
        <Column>
          <PayerIcon />
          <BodySmall>Plan Type</BodySmall>
        </Column>
        <Column>
          <BodySmallBold>{locationEpisode.planTypeClassification?.name || '—'}</BodySmallBold>
        </Column>
      </Row>
      <Row>
        <Column>
          <ClipboardIcon />
          <BodySmall>Episode Type</BodySmall>
        </Column>
        <ColumnWithMultipleRows>
          {(locationEpisode.episodeClassifications.length
            ? locationEpisode.episodeClassifications
            : [{ name: '—' } as Classification]
          ).map((ec) => (
            <BodySmallBold key={ec.id}>{ec.name}</BodySmallBold>
          ))}
        </ColumnWithMultipleRows>
      </Row>
    </TableSection>
  );
}

const TableSection = styled.div`
  padding-bottom: 8px;
`;
const TableHeader = styled.div`
  padding: 24px;
  color: var(--black-50);
`;
const Row = styled.div`
  display: flex;
  padding: 0 24px 16px;
  gap: 24px;
`;
const Column = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  align-content: start;
  gap: 12px;

  svg {
    min-width: 14px;
  }
`;
const ColumnWithMultipleRows = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
`;
