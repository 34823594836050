import React from 'react';
import styled, { css } from 'styled-components';

import CircularProgress from 'components/shared/CircularProgress';
import theme from 'styles/theme/theme';

type ButtonVariant = 'primary' | 'danger' | 'ghost' | 'icon' | 'outline-primary' | 'outline-danger' | 'outline-gray';

type Size = 'default' | 'small' | 'xsmall';

const variantStyles = (variant: ButtonVariant = 'primary') =>
  ({
    primary: css`
      color: ${theme.colors.white};
      background-color: ${theme.colors.primaryBlue};
      border-color: ${theme.colors.primaryBlue};

      &:hover {
        border-color: ${theme.colors.hoverPrimaryBlue};
        background-color: ${theme.colors.hoverPrimaryBlue};
      }

      &:focus {
        border-color: transparent;
        background-color: ${theme.colors.hoverPrimaryBlue};
        outline: 1.5px solid ${theme.colors.hoverPrimaryBlue};
        outline-offset: 1.5px;
      }
    `,
    danger: css`
      color: ${theme.colors.white};
      background-color: ${theme.colors.accentRed};
      border-color: ${theme.colors.accentRed};

      &:hover {
        border-color: ${theme.colors.hoverAccentRed};
        background-color: ${theme.colors.hoverAccentRed};
      }

      &:focus {
        border-color: transparent;
        background-color: ${theme.colors.hoverAccentRed};
        outline: 1.5px solid ${theme.colors.hoverAccentRed};
        outline-offset: 1.5px;
      }
    `,
    ghost: css`
      color: ${theme.colors.black75};
      background-color: ${theme.colors.white};
      border-color: transparent;

      &:focus {
        color: ${theme.colors.primaryBlue};
      }
    `,
    'outline-primary': css`
      background-color: white;
      color: ${theme.colors.primaryBlue};
      border-color: ${theme.colors.primaryBlue};

      &:focus {
        outline: 1.5px solid rgb(35, 59, 171);
        outline-offset: 1.5px;
      }
    `,
    'outline-danger': css`
      background-color: ${theme.colors.white};
      color: ${theme.colors.accentRed};
      border-color: ${theme.colors.accentRed};

      &:focus {
        outline: 1.5px solid rgb(147, 9, 47);
        outline-offset: 1.5px;
      }
    `,
    'outline-gray': css`
      background-color: ${theme.colors.white};
      color: ${theme.colors.black75};
      border-color: ${theme.colors.black75};

      &:focus {
        outline: 1.5px solid ${theme.colors.black75};
        outline-offset: 1.5px;
      }
    `,
    icon: css`
      padding: 0;
      border: none;
      outline: none;
      background: transparent;
    `,
  })[variant];

const sizeStyles = (size: Size = 'default') =>
  ({
    default: css`
      font-size: 16px;
    `,
    small: css`
      font-size: 14px;
    `,
    xsmall: css`
      font-size: 12px;
    `,
  })[size];

const BaseButton = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: ${theme.dimensions.borderRadius};
  padding: 8px 24px;
  font-weight: var(--font-weight-bold);
  line-height: 1.5;
  border-width: 1px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  outline: none;
  box-shadow: none;
  border-style: solid;
  text-wrap: nowrap;

  ${({ variant }) => variantStyles(variant)}
  ${({ size }) => sizeStyles(size)}

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`;

const loadingStyles = (variant: ButtonVariant = 'primary') =>
  ({
    primary: css`
      color: ${theme.colors.primaryBlue};
    `,
    danger: css`
      color: ${theme.colors.accentRed};
    `,
    ghost: css`
      color: ${theme.colors.black75};
    `,
    'outline-primary': css`
      background-color: white;
    `,
    'outline-danger': css`
      background-color: ${theme.colors.white};
    `,
  })[variant];

const ButtonProgress = styled(CircularProgress)<{ variant?: ButtonVariant }>`
  width: 10px;
  height: 10px;
  margin-right: 8px;

  ${({ variant }) => loadingStyles(variant)}
`;

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  variant?: ButtonVariant;
  size?: Size;
  fullWidth?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, forwardedRef) => {
  const {
    children,
    loading = false,
    type = 'button',
    variant = 'primary',
    size = 'default',
    fullWidth = false,
    ...rest
  } = props;

  return (
    <BaseButton
      ref={forwardedRef}
      type={type}
      variant={variant}
      size={size}
      disabled={rest.disabled || loading}
      fullWidth={fullWidth}
      data-cy='clickable'
      {...rest}>
      {!!loading && <ButtonProgress variant={variant} thickness={2} />}
      {children}
    </BaseButton>
  );
});

export default Button;
