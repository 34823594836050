import LocationEpisode, { LocationEpisodeOptions } from 'models/LocationEpisode';
import http from 'services/http';

type CreateParams = {
  locationEpisodeId: string;
  rehabStateId: string;
  enteredAt: string;
};

export async function createLocationEpisodeRehabState({ locationEpisodeId, ...params }: CreateParams) {
  return http
    .post<LocationEpisodeOptions>(`location_episodes/${locationEpisodeId}/location_episode_rehab_states`, params)
    .then((res) => new LocationEpisode(res.data));
}
