import { useNavigate, useParams } from 'react-router-dom';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import Intake from 'components/intake/Intake';
import { FormMeta, FormValues, PatientFormMode } from 'components/intake/types';
import { FormHelpers } from 'context/form/types';
import Patient from 'models/Patient';
import { episodeQueryKeys, showEpisode } from 'services/api/episode';
import { updatePatient } from 'services/api/patient';
import { useToastActions } from 'stores/toastStore';

export default function EditPatientPage() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { addToast } = useToastActions();
  const { id } = useParams<{ id?: string }>();

  const queryParams = {
    id: id ?? '',
    include: 'owner.client.enabled_provider_types,latest_location_episode.assigned_users.credential',
  };

  const { data: episode } = useQuery({
    queryKey: episodeQueryKeys.show(queryParams),
    queryFn: () => showEpisode(queryParams),
    enabled: !!id,
  });

  const { mutate } = useMutation({
    mutationFn: updatePatient,
  });

  if (!episode) return <></>;

  const handleSubmit = async (values: Partial<FormValues>, _meta, helpers: FormHelpers<FormValues, FormMeta>) => {
    helpers.setMeta('isSubmitting', true);

    const patientValues = Patient.fromFormValues(values).serialize();

    mutate(
      { ...patientValues, id: episode?.patient.id },
      {
        onSuccess: (patient) => {
          addToast({ text: 'Patient successfully updated.' });
          queryClient.invalidateQueries({ queryKey: episodeQueryKeys.show(queryParams) });
          navigate(`/patients/${patient.episodeId}`);
        },
        onError: () => {
          addToast({ text: 'Something went wrong. Please try again.' });
        },
        onSettled: () => {
          helpers.setMeta('isSubmitting', false);
        },
      }
    );
  };

  const initialValues = episode ? Patient.fromEpisodeData(episode).intakeFormValues() : {};

  return (
    <Intake
      initialMeta={{
        isSubmitting: false,
        mode: PatientFormMode.Edit,
        patientHasActiveServiceRefusals: !!episode.locationEpisodes?.some((le) => le.hasActiveServiceRefusals),
        currentRehabState: episode.currentRehabState.state,
        latestLocationEpisodeIsArchived: !!episode.latestLocationEpisode?.archived,
      }}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      patient={episode.patient}
    />
  );
}
