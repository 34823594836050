import { useState } from 'react';
import styled from 'styled-components';

import { Blurb } from 'components/Containers';
import AssignedUserModal from 'components/modals/AssignedUserModal';
import Tooltip from 'components/shared/Tooltip';
import LocationEpisode from 'models/LocationEpisode';
import { AssignedUserRole, LocationEpisodeUser } from 'models/LocationEpisodeUser';
import Profile from 'models/Profile';
import { colors } from 'styles/theme/colors';
import { BodySmallBold, Label, LabelBold } from 'styles/typography';
import InfoIconOutline from 'svg/InfoIconOutline';
import PencilIcon from 'svg/PencilIcon';

type AssignedUserProps = {
  patientName: string;
  locationEpisode: LocationEpisode;
  profile: Profile;
  assignedUser: LocationEpisodeUser | null;
  assignedUserRole: AssignedUserRole;
};

export function AssignedUser(props: AssignedUserProps) {
  const { locationEpisode, profile, patientName, assignedUser, assignedUserRole } = props;
  const [showModal, setShowModal] = useState(false);

  const canEdit = !locationEpisode.archived && profile.permissions.locationEpisodeUserEdit && profile.isAcute;
  const assignedUserText = assignedUserRole === AssignedUserRole.CaseManager ? 'Case Manager' : 'Utilization Manager';

  return (
    <Blurb data-cy='manager'>
      <Header>
        <Label>{assignedUserText}</Label>
        <Tooltip text={`${locationEpisode.owner?.client?.name} ${assignedUserText.toLowerCase()}`} position='right'>
          <StyledInfoIcon />
        </Tooltip>
        <Spacer />

        {canEdit && (
          <ActionContainer data-cy='clickable' onClick={() => setShowModal(true)}>
            <PencilIcon width={12} height={12} color={colors.primaryBlue} />
            <StyledLabel>Update</StyledLabel>
          </ActionContainer>
        )}
      </Header>

      <Content>
        <AssignedUserName>{assignedUser?.fullName ?? <>&mdash;</>}</AssignedUserName>
      </Content>

      {showModal && (
        <AssignedUserModal
          assignedUser={assignedUser}
          setShow={setShowModal}
          patientName={patientName}
          locationEpisode={locationEpisode}
          assignedUserRole={assignedUserRole}
        />
      )}
    </Blurb>
  );
}

const AssignedUserName = styled(BodySmallBold)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Header = styled.div`
  display: flex;
  padding: 24px 24px 8px;
  align-items: center;
`;

const StyledInfoIcon = styled(InfoIconOutline)`
  width: 14px;
  height: 14px;
  font-size: 14px;
  margin-left: 8px;
  color: ${colors.black75};
`;

const Content = styled.div`
  padding: 0 24px 24px;
`;
const Spacer = styled.div`
  flex: 1;
`;
const StyledLabel = styled(LabelBold)`
  margin-left: 8px;
  cursor: pointer;
  color: var(--primary-blue);
`;
const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
