import { useState } from 'react';

import ConfirmPatientRemovalModal from 'components/modals/ConfirmPatientRemovalModal';
import Button from 'components/shared/Button';
import Menu from 'components/shared/menu';
import { useProfile } from 'context/profile';
import Episode from 'models/Episode';
import colors from 'styles/theme/colors';
import CircleXIconOutlined from 'svg/CircleXIconOutlined';
import EllipsisIcon from 'svg/EllipsisIcon';

type Props = {
  episode: Episode;
};

export default function PatientActionsMenu({ episode }: Props) {
  const { profile } = useProfile();
  const [showRemovePatientModal, setShowRemovePatientModal] = useState(false);

  const canRemovePatient = !!profile.permissions.patientDelete;
  const showActionsMenu = canRemovePatient;

  return (
    <>
      {showActionsMenu && (
        <Menu>
          <Menu.Trigger>
            <Button
              variant='outline-gray'
              style={{ width: 44, padding: 0, height: 44, flexShrink: 0 }}
              data-cy='patientActionsMenuButton'>
              <EllipsisIcon />
            </Button>
          </Menu.Trigger>
          <Menu.Content position='right' style={{ marginTop: 4 }}>
            {canRemovePatient && (
              <Menu.Item style={{ color: colors.accentRed }} onClick={() => setShowRemovePatientModal(true)}>
                <CircleXIconOutlined />
                Remove Patient
              </Menu.Item>
            )}
          </Menu.Content>
        </Menu>
      )}
      {showRemovePatientModal && (
        <ConfirmPatientRemovalModal
          episodeId={episode.id}
          patientName={episode.patient.name}
          onCancel={() => setShowRemovePatientModal(false)}
        />
      )}
    </>
  );
}
