export default function CircleXIconOutlined({ width = 16, height = 16, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' viewBox='0 0 16 16' {...props}>
      <path
        stroke='currentColor'
        strokeLinecap='square'
        strokeMiterlimit='10'
        strokeWidth='.933'
        d='m10.8 5.2-5.6 5.6M10.8 10.8 5.2 5.2M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14Z'
      />
    </svg>
  );
}
