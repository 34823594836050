import { RedBold } from 'components/Containers';
import { dateWithoutTimezone } from 'lib/date';
import ArchiveLocationEpisode from 'models/activities/ArchiveLocationEpisode';
import DischargeQuestions from 'models/activities/DischargeQuestions';
import PatientMatchCollaboration from 'models/activities/PatientMatchCollaboration';
import RehabStateChange from 'models/activities/RehabStateChange';
import Activity, { ActivityType } from 'models/Activity';
import RehabState, { RehabStateName } from 'models/RehabState';
import { Label } from 'styles/typography';

type NoteContentHeaderProps = {
  activity: Activity;
};

const NoteContentHeader = (props: NoteContentHeaderProps) => {
  const { activity } = props;

  if (activity instanceof PatientMatchCollaboration) {
    return (
      <Label>
        {`${activity.data.collaboratorName} has been added as a ${activity.data.collaboratorType} by `}
        <b>{activity.createdBy.fullName}</b>
        {activity.createdByGroup?.name && ` at ${activity.createdByGroup.name}`}
      </Label>
    );
  }
  if (activity instanceof RehabStateChange) {
    const rehabFacilityText =
      (activity.data.currentRehabState as RehabState).state === RehabStateName.Discharged
        ? ''
        : `${activity.data.rehabFacility.name} by `;
    let rehabStateText = {
      [RehabStateName.Queue]: 'added to the queue at',
      [RehabStateName.Admission]: 'admitted to',
      [RehabStateName.InTreatment]: 'started treatment at',
      [RehabStateName.Discharged]: '<>',
    }[activity.data.currentRehabState.state];
    if (activity.data.currentRehabState.state === RehabStateName.Discharged) {
      const reason = activity.data.dischargeInfo.dischargeReason?.displayName || '';

      const dischargeReason = reason.charAt(0).toLowerCase() + reason.slice(1);
      const dischargeGroup = activity.data.dischargeInfo.group?.name || activity.data.dischargeInfo.locationOther;
      const againstMedicalAdvice = activity.data.dischargeInfo.againstMedicalAdvice;

      const dischargeReasonText =
        (dischargeReason || 'discharged; no reason available') +
        (againstMedicalAdvice ? ' (against medical advice)' : '');
      const dischargeGroupText = dischargeGroup ? `to ${dischargeGroup} ` : '';

      rehabStateText = `was ${dischargeReasonText}. Discharged from ${activity.data.rehabFacility.name} ${dischargeGroupText}by`;
    }
    return (
      <Label>
        {activity.patient?.name} {rehabStateText} {rehabFacilityText}
        <b> {activity.createdBy.fullName} </b>
        on {dateWithoutTimezone(activity.enteredAt!)}
      </Label>
    );
  }
  if (activity instanceof ArchiveLocationEpisode) {
    return (
      <Label>
        {activity.patient?.name} record at {activity.data.rehabFacility.name} was canceled by{' '}
        <b>{activity.createdBy.fullName}</b> on {dateWithoutTimezone(activity.createdAt!)}
      </Label>
    );
  }
  return (
    <Label>
      {activity instanceof DischargeQuestions && activity.edited && <i>(Edited) </i>}
      {activity.escalation?.isDefault ? <RedBold>Escalation {activity.typeLabel}</RedBold> : activity.typeLabel}
      {!!activity.attachments?.length && ' (with attachments)'}
      {activity.type === ActivityType.PATIENT_FIELD_CHANGES ? ' updated by ' : ' added by '}
      <b>{activity.createdBy.fullName}</b>
      {activity.createdByGroup?.name && ` at ${activity.createdByGroup.name}`}
    </Label>
  );
};

export default NoteContentHeader;
