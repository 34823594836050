import { FieldChangesContent } from 'components/patient/activities/FieldChangesContent';
import { NoteInnerContent } from 'components/patient/activities/NoteInnerContent';
import { QuestionsContent } from 'components/patient/activities/QuestionsContent';
import { StatusContent } from 'components/patient/activities/StatusContent';
import ProgressUpdate from 'models/activities/ProgressUpdate';
import Activity, { ActivityType } from 'models/Activity';
import LocationEpisode from 'models/LocationEpisode';

type NoteContentProps = {
  activity: Activity;
  locationEpisode: LocationEpisode;
};

const NoteContent = (props: NoteContentProps) => {
  const { activity, locationEpisode } = props;
  switch (activity.type) {
    case ActivityType.AUTHORIZATION_UPDATES:
    case ActivityType.PATIENT_FIELD_CHANGES:
    case ActivityType.PROJECTED_DISCHARGE_UPDATES:
      return <FieldChangesContent activity={activity} />;

    case ActivityType.REHAB_STATE_CHANGE:
    case ActivityType.ARCHIVE_LOCATION_EPISODE:
    case ActivityType.PATIENT_MATCH_COLLABORATION:
      return <></>;

    case ActivityType.DISCHARGE_QUESTIONS:
      return <QuestionsContent activity={activity} />;

    case ActivityType.PROGRESS_UPDATE:
      return activity instanceof ProgressUpdate && activity.isStatusUpdate ? (
        <StatusContent activity={activity} locationEpisode={locationEpisode} />
      ) : (
        <QuestionsContent activity={activity} />
      );

    default:
      return <NoteInnerContent activity={activity} locationEpisode={locationEpisode} />;
  }
};

export default NoteContent;
