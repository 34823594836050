import { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

import { useQuery } from '@tanstack/react-query';

import Tooltip from 'components/shared/Tooltip';
import Upload from 'hooks/useDirectUpload/Upload';
import Attachment from 'models/Attachment';
import { attachmentsQueryKeys, showAttachment } from 'services/api/attachments';

// "entry" can be an object where the "id" prop is a number or a GUID. We only
// want to make the link clickable and fetch the attachment if the "id" prop
// is a GUID.
const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

type DocumentLinkInput = {
  backgroundColor?: string;
  children: Iterable<ReactNode> | ReactNode;
  entry: Attachment | Upload;
  disabled?: boolean;
};

export default function DocumentLink(props: DocumentLinkInput) {
  const { backgroundColor, entry, children, disabled = false } = props;

  const deletedByClient = entry.fileDeletedByClient;
  const isDisabled = !!deletedByClient || disabled;
  const [attachmentToFetchId, setAttachmentToFetchId] = useState<string>('');

  const {
    data: attachment,
    isStale,
    refetch,
  } = useQuery({
    queryKey: attachmentsQueryKeys.show(attachmentToFetchId),
    queryFn: () => showAttachment({ id: attachmentToFetchId, include: 'url' }),
    enabled: !!attachmentToFetchId,
    staleTime: 29 * 1000, // Attachment URL is valid for 30 seconds
  });

  const openAttachment = (id: string) => {
    if (isStale) {
      refetch();
    } else if (attachment?.url) {
      window.open(attachment?.url, '_blank');
    } else {
      setAttachmentToFetchId(id);
    }
  };

  const handleClick = () => {
    if (!guidRegex.test(entry.id) || isDisabled) return;

    openAttachment(entry.id);
  };

  useEffect(() => {
    if (attachment?.url) {
      openAttachment(entry.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachment]);

  const deletedFileToolTip = `This file is no longer accessible due to ${deletedByClient?.name} file security policy`;

  return (
    <Tooltip text={deletedFileToolTip} disabled={!deletedByClient}>
      <Link
        backgroundColor={backgroundColor}
        onClick={handleClick}
        disabled={isDisabled}
        clickable={guidRegex.test(entry.id)}>
        {children}
      </Link>
    </Tooltip>
  );
}

const Link = styled.a<{
  backgroundColor?: string;
  disabled: boolean;
  clickable: boolean;
}>`
  display: block;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  cursor: ${(props) => props.disabled && 'not-allowed'};
  opacity: ${(props) => props.disabled && 0.25};
`;
