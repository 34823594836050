import { AnswerType } from 'components/modals/QuestionList/QuestionList';
import LocationEpisode, { LocationEpisodeOptions } from 'models/LocationEpisode';
import { Note } from 'models/Notes';
import http from 'services/http';

export type CreateParams = {
  locationEpisodeId: string;
  dischargeReasonId: string;
  dischargedGroupId?: string;
  dischargedGroupTypeId?: string;
  dischargedLocationOther: string | null;
  againstMedicalAdvice: boolean;
  dischargeQuestions: AnswerType[];
  enteredAt: string;
  note?: Note;
};

export async function createLocationEpisodeDischarge({ locationEpisodeId, ...params }: CreateParams) {
  return http
    .post<LocationEpisodeOptions>(`location_episodes/${locationEpisodeId}/location_episode_discharges`, params)
    .then((res) => new LocationEpisode(res.data));
}
