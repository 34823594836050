import Activity, {
  ActivityOptions,
  ActivityType,
  BaseActivityData,
  BaseActivityDataOptions,
  getDefaults as getActivityDefaults,
} from 'models/Activity';
import Group, { GroupOptions } from 'models/Group';

export interface ArchiveLocationEpisodeOptions extends ActivityOptions {
  type: ActivityType.ARCHIVE_LOCATION_EPISODE;
  data: Partial<
    BaseActivityDataOptions & {
      rehabFacility: Partial<GroupOptions>;
    }
  >;
}

export function getDefaults(): ArchiveLocationEpisodeOptions {
  const defaults = getActivityDefaults();

  return {
    ...defaults,
    type: ActivityType.ARCHIVE_LOCATION_EPISODE,
    data: {
      ...defaults.data,
      rehabFacility: {},
    },
  };
}

export default class ArchiveLocationEpisode extends Activity {
  declare type: ActivityType.ARCHIVE_LOCATION_EPISODE;
  declare data: {
    rehabFacility: Group;
  } & BaseActivityData;

  constructor(options: Partial<ArchiveLocationEpisodeOptions> = {}) {
    const opts = { ...getDefaults(), ...options };
    super(opts);

    this.data = {
      ...this.data,
      rehabFacility: new Group(opts.data.rehabFacility),
    };
  }
}
