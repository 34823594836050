import Group, { GroupOptions } from 'models/Group';

export interface LocationEpisodeSummaryOptions {
  id: string;
  archived: boolean;
  latest: boolean;
  hasActiveServiceRefusals: boolean;
  rehabInformation: {
    eligibleForProgressUpdate: boolean;
    latestRehabFacilityType: string;
    latestRehabFacility: Partial<GroupOptions>;
  };
  createdAt: Date | string;
}

function getDefaults(): LocationEpisodeSummaryOptions {
  return {
    id: '',
    archived: false,
    latest: false,
    hasActiveServiceRefusals: false,
    rehabInformation: {
      eligibleForProgressUpdate: false,
      latestRehabFacilityType: '',
      latestRehabFacility: {},
    },
    createdAt: new Date(),
  };
}
export default class LocationEpisodeSummary {
  id: string;
  archived: boolean;
  hasActiveServiceRefusals: boolean;
  latest: boolean;
  rehabInformation: {
    eligibleForProgressUpdate: boolean;
    latestRehabFacilityType: string;
    latestRehabFacility: Group;
  };
  createdAt: Date;

  constructor(options: Partial<LocationEpisodeSummaryOptions> = {}) {
    const opts = { ...getDefaults(), ...options };
    this.id = opts.id;
    this.archived = opts.archived;
    this.latest = opts.latest;
    this.hasActiveServiceRefusals = opts.hasActiveServiceRefusals ?? false;
    this.rehabInformation = {
      ...opts.rehabInformation,
      latestRehabFacility: new Group(opts.rehabInformation.latestRehabFacility),
    };
    this.createdAt = new Date(opts.createdAt);
  }

  get stayDescriptor() {
    if (this.archived) return 'Canceled';
    return this.latest ? 'Current' : 'Past';
  }
}
