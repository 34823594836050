import Classification, { ClassificationOptions } from 'models/Classification';
import Group, { GroupOptions } from 'models/Group';
import User, { UserOptions } from 'models/User';

import GroupType, { GroupTypeOptions } from './GroupType';

export enum NotificationChannel {
  EMAIL = 'email',
}

export type NotificationChannels = {
  [key in NotificationChannel]: boolean;
};

export interface NotificationsSelections {
  activity: Record<string, NotificationChannels>;
  criticalAlerts: Record<string, NotificationChannels>;
  patientStatusChanges: Record<string, NotificationChannels>;
}

export interface NotificationOptions {
  id: string;
  userId: string;
  clientId: string;
  active: boolean;
  groupTypeApiName: string;
  notificationSelections: NotificationsSelections;
  createdAt: Date;
  updatedAt: Date;
  type: string;
  groupType: Partial<GroupTypeOptions>;
  scopes: Partial<{
    episodes: Partial<ClassificationOptions>[];
    groups: Partial<GroupOptions>[];
    planTypes: Partial<ClassificationOptions>[];
    caseManagers: Partial<UserOptions>[];
    utilizationManagers: Partial<UserOptions>[];
  }>;
}

const getDefaults = (): NotificationOptions => ({
  id: '',
  userId: '',
  clientId: '',
  active: false,
  groupTypeApiName: '',
  notificationSelections: {
    activity: {},
    criticalAlerts: {},
    patientStatusChanges: {},
  },
  createdAt: new Date(),
  updatedAt: new Date(),
  type: '',
  groupType: {},
  scopes: {
    episodes: [],
    groups: [],
    planTypes: [],
    caseManagers: [],
    utilizationManagers: [],
  },
});

export class Notification {
  id: string;
  userId: string;
  clientId: string;
  active: boolean;
  groupTypeApiName: string;
  notificationSelections: NotificationsSelections;
  createdAt: Date;
  updatedAt: Date;
  type: string;
  groupType: GroupType;
  scopes: Partial<{
    episodes: Classification[];
    groups: Group[];
    planTypes: Classification[];
    caseManagers: User[];
    utilizationManagers: User[];
  }>;

  constructor(options: Partial<NotificationOptions> = {}) {
    const opts = { ...getDefaults(), ...options };

    this.id = opts.id;
    this.userId = opts.userId;
    this.clientId = opts.clientId;
    this.active = opts.active;
    this.groupTypeApiName = opts.groupTypeApiName;
    this.notificationSelections = opts.notificationSelections;
    this.createdAt = opts.createdAt;
    this.updatedAt = opts.updatedAt;
    this.type = opts.type;
    this.groupType = new GroupType(opts.groupType);
    this.scopes = {
      episodes: opts.scopes?.episodes?.map((episode) => new Classification(episode)),
      groups: opts.scopes?.groups?.map((group) => new Group(group)),
      planTypes: opts.scopes?.planTypes?.map((planType) => new Classification(planType)),
      caseManagers: opts.scopes?.caseManagers?.map((caseManager) => new User(caseManager)),
      utilizationManagers: opts.scopes?.utilizationManagers?.map((utilizationManager) => new User(utilizationManager)),
    };
  }
}
