import FormPage from 'components/layouts/page/FormPage';
import { FormContainer } from 'components/shared/form/Form';
import { Form, FormProvider } from 'context/form';
import { useProfile } from 'context/profile';
import Patient from 'models/Patient';

import { intakeFormValidation } from './intakeFormValidation';
import PatientForm from './PatientForm';
import ReviewPatientDetails from './ReviewPatientDetails';
import { FormMeta, FormValues } from './types';
import useIntakeSteps, { IntakeSteps } from './useIntakeSteps';

export type IntakeProps = {
  initialMeta: Partial<FormMeta>;
  initialValues: Partial<FormValues>;
  onSubmit: (values: Partial<FormValues>, meta: FormMeta, helpers: any) => void;
  patient: Patient;
};

export default function Intake({ patient, ...rest }: IntakeProps) {
  const steps = Object.values(IntakeSteps);
  const { step, handleNextStep, handlePreviousStep } = useIntakeSteps(steps);
  const { profile } = useProfile();

  const formChildren = (
    <FormPage>
      <Form>
        <FormContainer>
          {step === IntakeSteps.Information && <PatientForm patient={patient} onNextStep={handleNextStep} />}
          {step === IntakeSteps.Confirm && <ReviewPatientDetails onPreviousStep={handlePreviousStep} />}
        </FormContainer>
      </Form>
    </FormPage>
  );

  return (
    <>
      <FormProvider<FormValues, FormMeta> yupSchema={intakeFormValidation(profile.clientType)} {...rest}>
        {formChildren}
      </FormProvider>
    </>
  );
}
