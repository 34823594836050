import styled from 'styled-components';

import { SkeletonBlurb } from 'components/Containers';
import { colors } from 'styles/theme/colors';

import { Content, ModifiedBlurb, NoteHeader, SectionContainer, Separator } from './Layout';

const SkeletonActivityList = () => {
  return (
    <div>
      {[0, 1, 2, 3].map((i) => (
        <div key={i}>
          {i > 0 && <Separator />}
          <ModifiedBlurb color={colors.black10} $border={false}>
            <NoteHeader>
              <div className='icon'>
                <SkeletonBlank $width={24} $height={24} $rounding={4} />
              </div>
              <div>
                <SkeletonBlank $width={300} $height={12} $margin={5} />
                <SkeletonBlank $width={100} $height={12} />
              </div>
            </NoteHeader>
            <Content>
              <SectionContainer>
                <SkeletonBlank $width={200} $height={12} $margin={5} />
              </SectionContainer>
            </Content>
          </ModifiedBlurb>
        </div>
      ))}
    </div>
  );
};

export default SkeletonActivityList;

const SkeletonBlank = styled(SkeletonBlurb)<{
  $width: number;
  $height: number;
  $rounding?: number;
  $margin?: number;
}>`
  border-radius: ${({ $rounding, $height }) => ($rounding ?? $height) + 'px'};
  margin-bottom: ${({ $margin }) => ($margin ?? 0) + 'px'};
  height: ${({ $height }) => $height + 'px'};
  width: ${({ $width }) => $width + 'px'};
`;
