import Client from 'models/Client';
import User from 'models/User';

export const ALLOWED_IMAGE_TYPES: string[] = ['.jpeg', '.jpg', '.png', '.gif', '.tiff'];
export const ALLOWED_DOCUMENT_TYPES: string[] = [
  '.doc',
  '.docx',
  '.odt',
  '.pdf',
  '.xls',
  '.xlsx',
  '.ods',
  '.ppt',
  '.pptx',
  '.txt',
  '.rtf',
  '.pages',
  '.csv',
  '.key',
  '.numbers',
  '.odp',
  '.gdoc',
  '.gslides',
  '.gsheet',
];

export enum DocumentTypes {
  Authorization = 'Authorization Letter',
  CarePlan = 'Care Plan',
  CCD = 'CCD',
  Denial = 'Denial Letter',
  DischargeSummary = 'Discharge Summary',
  EDSummary = 'ED Summary',
  MedList = 'Medication List',
  NOMNC = 'NOMNC',
  Other = 'Other',
  ReferralResource = 'Referral Resource',
  TherapyNotes = 'Therapy Notes',
}

type AttachmentFile = {
  name: string;
  document: File | null;
};

export type AttachmentBlob = {
  id: string;
  signedId: string;
  filename: string;
  contentType?: string;
  metadata?: Record<string, unknown>;
};

export interface AttachmentOptions {
  id: string;
  file: AttachmentFile;
  key: string | number;
  thumbnailUrl: string;
  createdAt: string | null;
  createdByUser: Partial<User>;
  docType: string | null;
  blob: AttachmentBlob;
  url?: string;
  fileDeletedByClient: Client | null;
}

/**
 * @class Attachment
 * @classdesc Represents an attachment in the system
 * @property {string} id - The attachment's id
 * @property {AttachmentFile} file - The attachment's file
 * @property {string | number} key - The attachment's key
 * @property {string} thumbnailUrl - The attachment's thumbnail url
 * @property {string | null} createdAt - The attachment's creation date
 * @property {User} createdByUser - The user who created the attachment
 * @property {string | null} docType - The attachment's document type
 * @property {AttachmentBlob} blob - The attachment's blob
 * @property {Client | null} fileDeletedByClient - The client who deleted the attachment
 * @param {Partial<AttachmentOptions>} [options={}]
 */
export default class Attachment {
  id: string;
  file: AttachmentFile;
  key: string | number;
  thumbnailUrl: string;
  createdAt: string | null;
  createdByUser: User | null;
  docType: string | null;
  blob: AttachmentBlob;
  url?: string;
  fileDeletedByClient: Client | null;

  constructor(options?: Partial<AttachmentOptions>) {
    const opts = { ...options };

    this.id = opts.id || '';
    this.file = opts.file || { name: '', document: null };
    this.key = opts.id || opts.key || Date.now();
    this.thumbnailUrl = opts.thumbnailUrl || '';
    this.createdAt = opts.createdAt || null;
    this.createdByUser = opts.createdByUser ? new User(opts.createdByUser) : null;
    this.docType = opts.docType || null;
    this.url = opts.url;
    this.fileDeletedByClient = opts.fileDeletedByClient || null;

    this.blob = opts.blob || {
      id: '',
      signedId: '',
      filename: '',
      contentType: '',
      metadata: {},
    };
  }

  get isImage() {
    return this.blob.contentType ? /image/.test(this.blob.contentType) : false;
  }

  get signedId() {
    return this.blob.signedId;
  }

  get filename() {
    return this.blob.filename;
  }

  get metadata() {
    return this.blob.metadata;
  }

  get contentType() {
    return this.blob.contentType;
  }

  serialize() {
    return {
      id: this.id,
      filename: this.filename,
      signedId: this.signedId,
      docType: this.docType,
    };
  }
}
