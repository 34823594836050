import React from 'react';
import styled from 'styled-components';

import Attachment from 'models/Attachment';
import { colors } from 'styles/theme/colors';
import { BodySmall } from 'styles/typography';
import RemovedImageIcon from 'svg/RemovedImageIcon';

import LazyImage from './LazyImage';

type ThumbnailInput = {
  attachment: Attachment;
  className?: string;
  onClick?: () => void;
  archived: boolean;
};

export default function Thumbnail(props: ThumbnailInput) {
  const { attachment, className, onClick, archived } = props;

  const deletedByClient = attachment.fileDeletedByClient;
  const isDisabled = !!deletedByClient || archived;
  const removedText = deletedByClient
    ? `This file is no longer accessible due to ${deletedByClient?.name} file security policy`
    : 'Image has been removed';

  return (
    <React.Fragment>
      {isDisabled ? (
        <RemovedImage className={className}>
          <RemovedImageIcon />
          <StyledBodySmall>{removedText}</StyledBodySmall>
        </RemovedImage>
      ) : (
        <LazyThumbnail
          className={className}
          customStyles={{
            maxHeight: '80px',
            maxWidth: '80px',
          }}
          src={attachment.thumbnailUrl}
          alt='attached image'
          onClick={onClick}
        />
      )}
    </React.Fragment>
  );
}

const RemovedImage = styled.div`
  display: flex;
  align-items: center;
`;

const StyledBodySmall = styled(BodySmall)`
  margin-left: 6px;
  font-style: italic;
  color: ${colors.black50};
`;

const LazyThumbnail = styled(LazyImage)`
  cursor: pointer;
`;
