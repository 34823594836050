import Activity, { ActivityType } from 'models/Activity';
import ActivityAltcsApplicationStatusUpdateIcon from 'svg/ActivityAltcsApplicationStatusUpdateIcon';
import ActivityAuthorizationUpdatesIcon from 'svg/ActivityAuthorizationUpdatesIcon';
import ActivityDischargeQuestionsIcon from 'svg/ActivityDischargeQuestionsIcon';
import ActivityEscalationIcon from 'svg/ActivityEscalationIcon';
import ActivityNoteIcon from 'svg/ActivityNoteIcon';
import ActivityPatientFieldChangeIcon from 'svg/ActivityPatientFieldChangeIcon';
import ActivityPatientMatchIcon from 'svg/ActivityPatientMatchIcon';
import ActivityProgressUpdateIcon from 'svg/ActivityProgressUpdateIcon';
import ActivityProjectedDischargeUpdatesIcon from 'svg/ActivityProjectedDischargeUpdatesIcon';
import ActivityRefuseServiceIcon from 'svg/ActivityRefuseServiceIcon';
import ActivityStateChangeIcon from 'svg/ActivityStateChangeIcon';
type NoteIconProps = {
  activity: Activity;
};

const NoteIcon = (props: NoteIconProps) => {
  switch (props.activity.type) {
    case ActivityType.ALTCS_APPLICATION_STATUS_UPDATE:
      return <ActivityAltcsApplicationStatusUpdateIcon />;
    case ActivityType.AUTHORIZATION_UPDATES:
      return <ActivityAuthorizationUpdatesIcon />;
    case ActivityType.DISCHARGE_QUESTIONS:
      return <ActivityDischargeQuestionsIcon />;
    case ActivityType.PATIENT_FIELD_CHANGES:
      return <ActivityPatientFieldChangeIcon />;
    case ActivityType.PATIENT_MATCH_COLLABORATION:
      return <ActivityPatientMatchIcon />;
    case ActivityType.PROGRESS_UPDATE:
      return <ActivityProgressUpdateIcon />;
    case ActivityType.PROJECTED_DISCHARGE_UPDATES:
      return <ActivityProjectedDischargeUpdatesIcon />;
    case ActivityType.REHAB_STATE_CHANGE:
      return <ActivityStateChangeIcon />;
    case ActivityType.ARCHIVE_LOCATION_EPISODE:
    case ActivityType.SERVICE_REFUSAL:
      return <ActivityRefuseServiceIcon />;
    default:
      if (props.activity.escalation?.isDefault) {
        return <ActivityEscalationIcon />;
      }
      return <ActivityNoteIcon />;
  }
};

export default NoteIcon;
