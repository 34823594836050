import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Histogram from 'components/shared/charts/Histogram';
import { Flags } from 'constants/flags';
import useIsMobile from 'hooks/useIsMobile';
import { Analytics } from 'services/api/insights/analytics';
import { useInsightsStore } from 'stores/insightsStore';

import { DimensionConstants, MetricConstants, RouteConstants, SortOrder, SourceConstants } from '../../../constants';
import { useInsightsContext } from '../../../InsightsContainer';
import {
  groupByDateIndex,
  mapPreviousDataToCurrentCategories,
  parseDimensionValues,
  parseMetricValues,
} from '../../helpers/dataUtils';
import useInsightsQuery from '../../helpers/useInsightsQuery';
import ChartContainer from '../ChartContainer';
import ComparePriorPeriodToggle from '../ComparePriorPeriodToggle';
import { generateExtendedChartConfig } from '../helpers/readmissionRateUtils';
import MetricDetailContainer from '../MetricDetailContainer';
import MetricDetailHeader from '../MetricDetailHeader';
import MetricDiffBadge, { Comparator } from '../MetricDiffBadge';
import ViewBySelect from '../ViewBySelect';

import ReadmissionRateDataTable from './ReadmissionRateDataTable';
import ReadmittedPatientsDataTable from './ReadmittedPatientsDataTable';

const ReadmissionRateDetail = () => {
  const { profile } = useInsightsContext();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);
  const comparePriorPeriod = useInsightsStore((state) => state.comparePriorPeriod);

  const [selectedDimension, setSelectedDimension] = useState<DimensionConstants>(
    profile.isAcute ? DimensionConstants.PROVIDER_CLIENT : DimensionConstants.GROUP_NAME
  );

  const request = useMemo(
    () => ({
      params: {
        source: SourceConstants.LOCATION_EPISODE_DISCHARGES,
        dimensions: [selectedDimension],
        metrics: [MetricConstants.ID_COUNT, MetricConstants.READMISSIONS_SUM, MetricConstants.READMISSION_RATE],
        sortBy: `${MetricConstants.READMISSION_RATE} ${SortOrder.DESC}`,
        rollups: true,
      },
      processData: (unprocessedData: Analytics) => {
        const groupedByDateIndex = groupByDateIndex(unprocessedData);

        if (!groupedByDateIndex.length) {
          return null;
        }

        const parsedData = groupedByDateIndex.map((row) => {
          return row.reduce(
            (acc, row) => {
              const dimensionValues = parseDimensionValues(row) as string[];
              const metricValues = parseMetricValues(row);

              const groupName = dimensionValues[0];
              const discharges = metricValues[0];
              const readmissions = metricValues[1];
              const readmissionRate = metricValues[2];

              if (!groupName && row.grouping == 1) {
                acc.averageRate = +readmissionRate === 0 ? 0 : readmissionRate;
                acc.totalDischarges = discharges;
                acc.totalReadmissions = readmissions;
              } else {
                acc.categories.push(groupName || 'None');
                acc.dischargeCounts.push(discharges);
                acc.readmissionCounts.push(readmissions);
                acc.readmissionRates.push(readmissionRate);
              }

              return acc;
            },
            {
              categories: [],
              dischargeCounts: [],
              readmissionCounts: [],
              readmissionRates: [],
              averageRate: 0,
              totalDischarges: 0,
              totalReadmissions: 0,
            } as {
              categories: string[];
              dischargeCounts: number[];
              readmissionCounts: number[];
              readmissionRates: number[];
              averageRate: number;
              totalDischarges: number;
              totalReadmissions: number;
            }
          );
        });

        const { totalDischarges, totalReadmissions, averageRate, ...chartData } = parsedData[0];

        const priorPeriod = parsedData[1];

        const priorPeriodRates = comparePriorPeriod
          ? mapPreviousDataToCurrentCategories('readmissionRates', chartData, priorPeriod)
          : [];

        const priorPeriodReadmissionCounts = comparePriorPeriod
          ? mapPreviousDataToCurrentCategories('readmissionCounts', chartData, priorPeriod)
          : [];

        const priorPeriodDischargeCounts = comparePriorPeriod
          ? mapPreviousDataToCurrentCategories('dischargeCounts', chartData, priorPeriod)
          : [];

        return {
          chartData,
          averageRate,
          totalDischarges,
          totalReadmissions,
          priorPeriodChartData: {
            readmissionRates: priorPeriodRates,
            readmissionCounts: priorPeriodReadmissionCounts,
            totalDischarges: priorPeriod?.totalDischarges || null,
            averageRate: priorPeriod?.averageRate ?? null,
            dischargeCounts: priorPeriodDischargeCounts,
          },
        };
      },
    }),
    [selectedDimension, comparePriorPeriod]
  );

  const { loading, query } = useInsightsQuery(request);

  const {
    chartData = { categories: [], dischargeCounts: [], readmissionCounts: [], readmissionRates: [] },
    averageRate = 0,
    totalDischarges = 0,
    totalReadmissions = 0,
    priorPeriodChartData = {
      readmissionRates: [],
      readmissionCounts: [],
      totalDischarges: null,
      averageRate: null,
      dischargeCounts: [],
    },
  } = query.data || {};

  const chartConfig = useMemo(
    () => generateExtendedChartConfig(chartData, priorPeriodChartData),
    [chartData, priorPeriodChartData]
  );

  const getAverageRateString = () => {
    if (!totalDischarges) return;

    return `${averageRate.toFixed(1)}%`;
  };

  const getAllReadmissionsString = () => {
    if (!totalDischarges) return;

    return totalReadmissions.toString();
  };

  const getTableHeader = useMemo(() => {
    switch (selectedDimension) {
      case DimensionConstants.PLAN_TYPE:
        return 'Plan Type';
      case DimensionConstants.EPISODE_TYPE:
        return 'Episode Type';
      case DimensionConstants.PROVIDER_CLIENT:
        return `${selectedGroupType?.displayName} Company`;
      default:
        return selectedGroupType?.displayName || '';
    }
  }, [selectedDimension, selectedGroupType?.displayName]);

  return (
    <>
      {profile.hasFlag(Flags.Insights) && (
        <ComparePriorPeriodToggle selectedLegendColor='#DC2853' priorLegendColor='#E8ADBE' />
      )}
      <MetricDetailContainer
        onBackClick={() => navigate(RouteConstants.INSIGHTS_BASE)}
        loading={loading}
        hasData={!!chartData.categories.length}
        header={
          <>
            <MetricDetailHeader
              label='All Cause Readmission Rate'
              loading={loading}
              hasData={!!chartData.categories.length}
              value={
                <>
                  {getAverageRateString()}
                  <MetricDiffBadge
                    diff={
                      priorPeriodChartData.averageRate !== null ? averageRate - priorPeriodChartData.averageRate : null
                    }
                    comparator={Comparator.LESS_THAN}
                    formatter={(val) => `${val.toFixed(1)}%`}
                  />
                </>
              }
            />
            <Separator />
            <MetricDetailHeader
              label='All Cause Readmissions'
              loading={loading}
              value={getAllReadmissionsString()}
              hasData={!!chartData.categories.length}
            />
            {!isMobile && (
              <ViewBySelect
                selectedDimension={selectedDimension}
                groupType={selectedGroupType}
                onChange={(selectedOption) => {
                  setSelectedDimension(selectedOption.value);
                }}></ViewBySelect>
            )}
          </>
        }>
        <ChartContainer>
          <Histogram config={chartConfig} />
        </ChartContainer>
      </MetricDetailContainer>
      <ReadmissionRateDataTable
        averageRate={averageRate}
        data={chartData}
        loading={loading}
        totalDischarges={totalDischarges}
        totalReadmissions={totalReadmissions}
        firstColumnHeader={getTableHeader}
        previousData={priorPeriodChartData}
      />
      <ReadmittedPatientsDataTable />
    </>
  );
};

export default ReadmissionRateDetail;

const Separator = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.black15};
  margin: 0 24px;
`;
