import { format, startOfMonth, startOfYear, subDays } from 'date-fns';
import styled from 'styled-components';

import { Chip, ChipBag } from 'components/shared/FilterChips';
import { useInsightsStore } from 'stores/insightsStore';

const today = new Date();

const shortcutItems = [
  { label: 'This Month', value: [startOfMonth(today), today] },
  { label: 'Last 30 Days', value: [subDays(today, 30), today] },
  { label: 'Last 90 Days', value: [subDays(today, 90), today] },
  { label: 'Year To Date', value: [startOfYear(today), today] },
];

const DatePickerShortcuts = ({ datePickerRef, setDates }) => {
  const formatString = 'MM-dd-yyyy';

  const formattedStartDate = useInsightsStore((state) => format(state.filters.startDate as Date, formatString));
  const formattedEndDate = useInsightsStore((state) => format(state.filters.endDate as Date, formatString));

  return (
    <StyledChipBag>
      {shortcutItems.map((item) => {
        const formattedItemStartDate = format(item.value[0], formatString);
        const formattedItemEndDate = format(item.value[1], formatString);

        return (
          <StyledChip
            $isHighlighted={formattedStartDate === formattedItemStartDate && formattedEndDate === formattedItemEndDate}
            key={item.label}
            onClick={() => {
              setDates(item.value);
              datePickerRef.current.setOpen(false);
            }}>
            {item.label}
          </StyledChip>
        );
      })}
    </StyledChipBag>
  );
};

export default DatePickerShortcuts;

const StyledChip = styled(Chip)<{ $isHighlighted: boolean }>`
  cursor: pointer;
  font-weight: normal;

  ${({ $isHighlighted, theme }) => $isHighlighted && `background-color: ${theme.colors.primaryBlue}; color: white;`}
`;

const StyledChipBag = styled(ChipBag)`
  gap: 8px;
  justify-content: center;
`;
