export default function EllipsisIcon({ width = 16, height = 3, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' viewBox='0 0 16 3' {...props}>
      <g fill='currentColor' clipPath='url(#ellipsis-icon)'>
        <path d='M8 2.91A1.455 1.455 0 1 0 8 0a1.455 1.455 0 0 0 0 2.91ZM1.455 2.91a1.455 1.455 0 1 0 0-2.91 1.455 1.455 0 0 0 0 2.91ZM14.546 2.91a1.455 1.455 0 1 0 0-2.91 1.455 1.455 0 0 0 0 2.91Z' />
      </g>
      <defs>
        <clipPath id='ellipsis-icon'>
          <path fill='#fff' d='M0 0h16v2.909H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}
